import { Container, Nav, Navbar, Col, Row } from 'react-bootstrap';

import './App.css';

function App() {
  return (
    <>
    <Navbar className='App-Nav' fixed='top' expand={true}>
      <Container>
        {/* <Navbar.Brand>
          <h1>drunkbingo.com</h1>
        </Navbar.Brand> */}
        <Nav className='m-auto'>
        </Nav>
      </Container>
    </Navbar>
    <Container className='App'>
      <Row className='intro'>
        <Col><p>Coming Soon...</p>
        <span className='intro-text'>DRUNK BINGO!</span>
        </Col>
      </Row>
    </Container>
    </>
    
  );
}

export default App;
